<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Merchant Filters</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1" v-if="hasPermission('ROLE_VIEW_MERCHANT_FILTER')">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_UPDATE_MERCHANT_FILTER')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showMerchantFilterForm('UPDATE')" data-cy="button-merchant-filter">Merchant Filter</v-btn>
                                <merchant-filter-form ref="merchantFilterForm" :selected-item="selectedMerchantFilter" :is-show="showFormDialog" :form-type="merchantFilterFormType" :merchants="merchants" v-on:close-dialog="closeDialog" v-on:save-merchant-filter="saveMerchantFilter" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayMerchantFilters" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMerchantFilters" :footer-props="{'items-per-page-options': [totalMerchantFilters]}" class="elevation-2 mt-4 px-4" :hide-default-footer="true" :disable-pagination="true">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.merchantName }} </span></td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import MerchantFilterForm from '@/views/components/MerchantFilterForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'MerchantFilters',
    components: {
        Loading,
        MerchantFilterForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayMerchantFilters: [],
            remainderMerchantFilters: [],
            // authorities: [],
            merchants: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            merchantFilterFormType: 'UPDATE',
            selectedMerchantFilter: [],
            page: 1,
            totalMerchantFilters: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 1000
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                type: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const merchantFilterListResponse = await API.getFilteredMerchantsRecon(pageNumber, limit, this.query)
                if (!merchantFilterListResponse || merchantFilterListResponse.error) {
                    //error getting data
                    console.log(`${merchantFilterListResponse.error}`)
                } else {
                    var filteredList = merchantFilterListResponse.merchantFilters
                    if (page > 1 && this.remainderMerchantFilters.length > 0) {
                        filteredList = this.remainderMerchantFilters.concat(filteredList)
                    }
                    this.totalMerchantFilters = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMerchantFilters = filteredList.slice(0, end)
                    this.remainderMerchantFilters = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMerchantFilters / itemsPerPage
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
          try {
                const listResponse = await API.getAllMerchantsRecon()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchants = listResponse.merchants
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Merchant Name',
                    value: 'merchantName'
                }
            ]
            this.headers = headers
        },
        showMerchantFilterForm(what, item = {}) {
            this.merchantFilterFormType = what
            this.selectedMerchantFilter = this.displayMerchantFilters
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveMerchantFilter(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
              console.log(value)
                if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant filter`
                    setTimeout(async () => {
                        const updateResponse = await API.updateMerchantFilterRecon(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated merchant filter`
                            this.showFormDialog = false
                            this.$refs.merchantFilterForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
